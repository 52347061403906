import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import React, { useState } from 'react';
import { MdOutlineLocationOn, MdOutlineUpload } from 'react-icons/md';

import styles from './Body.module.scss';

import { Row } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Body = ({ robotId }) => {
  const [telemetry, setTelemetry] = useState();

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry`, setTelemetry);

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return (
    <div className={cx('container')}>
      <div className={cx('values')}>
        <Row
          icon={MdOutlineLocationOn}
          label="Location"
          value={`${telemetry?.latitude.toFixed(6) ?? 0}, ${telemetry?.longitude.toFixed(6) ?? 0}`}
        />
        <Row
          icon={MdOutlineUpload}
          label="MSL Altitude"
          value={telemetry ? commaNumber(telemetry.altitude.toFixed(2)) : 0}
          unit="m"
        />
      </div>
    </div>
  );
};

export default Body;
