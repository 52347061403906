import classNames from 'classnames/bind';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import { MessageContext } from '@/helpers/MessageProvider';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);
  const [inputs, setInputs] = useState({
    servo: 1,
    pwm: 1800,
  });

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: Number(e.target.value),
    });
  };

  const doCommand = () => {
    robots.forEach((robot) => {
      publishCommand(robot, 'action/set_servo', [[inputs.servo, inputs.pwm]]);
    });

    dispatch(actions.command.toggle());
  };

  return (
    <div className={cx('container')}>
      <div className={cx('form')}>
        <label>
          Instance Number
          <div className={cx('fieldWrapper')}>
            <input name="servo" type="number" value={inputs.servo} onChange={handleChange} />
          </div>
        </label>
        <label>
          PWM
          <div className={cx('fieldWrapper')}>
            <input name="pwm" type="number" value={inputs.pwm} onChange={handleChange} />
          </div>
        </label>
      </div>
      <ConfirmSlider onConfirm={doCommand} />
    </div>
  );
};

export default Action;
