import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import actions from '@/actions';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import { MessageContext } from '@/helpers/MessageProvider';

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);

  const doCommand = () => {
    robots.forEach((robot) => {
      publishCommand(robot, 'action/takeoff', [[]]);
    });

    dispatch(actions.command.toggle());
  };

  return <ConfirmSlider onConfirm={doCommand} />;
};

export default Action;
