// Bing Maps API Key
export const BING_API_KEY = 'Aq5oeWKxjX7dq5qjQnTTi-odvsOlE_qo2JrA3DCxcpBrrMY66YtwE3bWQPTSpOsh';
// VWorld API Key
export const VWORLD_API_KEY = '680783E1-FA80-3E05-B14F-1ED8B51C3A29';

// OpenLayers
export const OL_DEFAULT_MAP_ID = 'BING';
export const OL_DEFAULT_CENTER = { lat: 37.55, lng: 127.05 };
export const OL_DEFAULT_ZOOM = 17;
export const OL_MAX_ZOOM = 19;
export const OL_RESTRICTED_BOUNDS = {
  north: OL_DEFAULT_CENTER.lat + 0.5,
  south: OL_DEFAULT_CENTER.lat - 0.5,
  west:  OL_DEFAULT_CENTER.lng - 0.5,
  east:  OL_DEFAULT_CENTER.lng + 0.5,
};
export const OL_VWORLD_BOUNDS = {
  north: 45.0,
  south: 30.0,
  west: 116.0,
  east: 141.0,
};

// Virtualearth
export const VIRTUALEARTH_BASE_URL = 'https://dev.virtualearth.net/REST/v1';

// Survey
export const SURVEY_DEFAULT_OPTIONS = {
  gap: 50,
  rotate: 0,
  turnaround: { before: 0, after: 0 },
  camera: null,
};
export const SURVEY_GAP_MIN = 20;
export const SURVEY_GAP_MAX = 500;
export const SURVEY_TURNAROUND_MIN = 0;
export const SURVEY_TURNAROUND_MAX = 1000;
export const SURVEY_AREA_MAX = 200000000; // 200km²

// Camera
export const OVERLAP_DEFAULT_OPTIONS = {
  forward: 10,
  side: 10,
};
export const OVERLAP_MIN = 0;
export const OVERLAP_MAX = 99;

// Video
export const VIDEO_DEFAULT_WIDTH = 280;
export const VIDEO_DEFAULT_HEIGHT = VIDEO_DEFAULT_WIDTH * 0.66;

// Chart
export const CHART_MIN_WIDTH = 720;
export const CHART_HEIGHT = 240;