import { degreesToRadians, radiansToDegrees } from '@turf/turf';
import classNames from 'classnames/bind';
import React, { useMemo, useRef, useEffect } from 'react';
import { useDispatch, useStore } from 'react-redux';

import styles from './common.module.scss';

import actions from '@/actions';
import Checkbox from '@/components/ui/Checkbox';
import MissionItemRow from '@/components/ui/MissionItemRow';
import {
  SURVEY_GAP_MIN,
  SURVEY_GAP_MAX,
  SURVEY_TURNAROUND_MIN,
  SURVEY_TURNAROUND_MAX,
  OVERLAP_DEFAULT_OPTIONS,
  OVERLAP_MIN,
  OVERLAP_MAX,
} from '@/config';
import { CAMERAS } from '@/define/camera';
import { ToastService as toast } from '@/libs/Toast';
import { deepCopy, nestedAssign } from '@/utils/ObjectUtils';
import { getPositions } from '@/utils/SurveyCalculator';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const domRefs = useRef({
    relAltitude: useRef(),
    gap: useRef(),
    turnaround: {
      before: useRef(),
      after: useRef(),
    },
    rotate: useRef(),
    camera: useRef(),
    overlap: {
      forward: useRef(),
      side: useRef(),
    },
  }).current;

  // 카메라 사용여부
  const hasCamera = useMemo(() => {
    return missionItem.data.camera !== null;
  }, [missionItem.data.camera]);

  // 촬영 수
  const shootCount = useMemo(() => {
    return store.getState().editor.shoots.filter(({ id }) => id.startsWith(missionItem.id)).length;
  }, [missionItem.data]);

  useEffect(() => {
    setFieldsToOrigin();
  }, [missionItem.data]);

  const setFieldsToOrigin = () => {
    domRefs.relAltitude.current.value = missionItem.data.altitude.toFixed(1);
    domRefs.gap.current.value = missionItem.data.gap.toFixed(1);
    domRefs.turnaround.before.current.value = missionItem.data.turnaround.before.toFixed(1);
    domRefs.turnaround.after.current.value = missionItem.data.turnaround.after.toFixed(1);
    domRefs.rotate.current.value = missionItem.data.rotate;

    if (hasCamera) {
      domRefs.camera.current.value = missionItem.data.camera.name;
      domRefs.overlap.forward.current.value = missionItem.data.camera.options.overlap.forward.toFixed(1);
      domRefs.overlap.side.current.value = missionItem.data.camera.options.overlap.side.toFixed(1);
    } else {
      domRefs.camera.current.value = '';
    }
  };

  const changeRelAltitude = (e) => {
    const altitude = Number(e.target.value);

    if (hasCamera) {
      const camera = deepCopy(missionItem.data.camera);
      camera.interval = getDistanceByOptions(altitude, camera.options.aov.vertical, camera.options.overlap.forward);

      const gap = getDistanceByOptions(
        altitude,
        missionItem.data.camera.options.aov.horizontal,
        missionItem.data.camera.options.overlap.side
      );
      const positions = getPositionsByChangedOption({ gap });

      editSurvey({ altitude, gap, positions, camera });
    } else {
      editSurvey({ altitude });
    }
  };

  const changeGap = (e) => {
    let gap = Number(e.target.value);
    gap = Math.max(gap, SURVEY_GAP_MIN);
    gap = Math.min(gap, SURVEY_GAP_MAX);

    const positions = getPositionsByChangedOption({ gap });

    editSurvey({ gap, positions });
  };

  const changeTurnaround = (e) => {
    const name = e.target.name.split('.')[1];

    let value = Number(e.target.value);
    value = Math.max(value, SURVEY_TURNAROUND_MIN);
    value = Math.min(value, SURVEY_TURNAROUND_MAX);

    const turnaround = deepCopy(missionItem.data.turnaround);
    turnaround[name] = value;

    const positions = getPositionsByChangedOption({ turnaround });

    editSurvey({ turnaround, positions });
  };

  const changeRotate = (e) => {
    let rotate = Number(e.target.value);
    rotate = parseInt(rotate) % 360;
    if (rotate < 0) {
      rotate = 360 - Math.abs(rotate);
    }
    rotate %= 180;

    const positions = getPositionsByChangedOption({ rotate });

    editSurvey({ rotate, positions });
  };

  const changeCamera = (camera) => {
    const aov = {
      vertical: getAov(camera.specs.sensorSize.height, camera.specs.focalLength),
      horizontal: getAov(camera.specs.sensorSize.width, camera.specs.focalLength),
    };

    const interval = getDistanceByOptions(missionItem.data.altitude, aov.vertical, OVERLAP_DEFAULT_OPTIONS.forward);
    const gap = getDistanceByOptions(missionItem.data.altitude, aov.horizontal, OVERLAP_DEFAULT_OPTIONS.side);
    const positions = getPositionsByChangedOption({ gap, camera });

    editSurvey({
      gap,
      positions,
      camera: {
        name: camera.name,
        interval,
        options: {
          ...camera.specs,
          aov,
          overlap: OVERLAP_DEFAULT_OPTIONS,
        },
      },
    });
  };

  const handleSelect = (e) => {
    const found = CAMERAS.find(({ name }) => name === e.target.value);
    changeCamera(found);
  };

  const changeOverlap = (e) => {
    const name = e.target.name;

    let value = Number(e.target.value);
    value = Math.max(value, OVERLAP_MIN);
    value = Math.min(value, OVERLAP_MAX);

    const camera = deepCopy(missionItem.data.camera);
    nestedAssign(camera.options, name.split('.'), value);

    if (name.endsWith('forward')) {
      camera.interval = getDistanceByOptions(
        missionItem.data.altitude,
        camera.options.aov.vertical,
        camera.options.overlap.forward
      );
    }

    const gap = getDistanceByOptions(
      missionItem.data.altitude,
      camera.options.aov.horizontal,
      camera.options.overlap.side
    );
    const positions = getPositionsByChangedOption({ gap });

    editSurvey({ gap, positions, camera });
  };

  const editSurvey = (values) => {
    if (values.positions?.length === 0) {
      toast.error('The area is too small.');
      // 이전 값 복원
      setFieldsToOrigin();
      return;
    }

    dispatch(actions.editor.editSurvey(index, values));
  };

  const getAov = (sensorSize, focalLength) => {
    const radian = 2 * Math.atan(sensorSize / (focalLength * 2));
    return radiansToDegrees(radian);
  };

  const getPositionsByChangedOption = (values) => {
    return getPositions(missionItem.data.boundary, {
      gap: missionItem.data.gap,
      turnaround: missionItem.data.turnaround,
      rotate: missionItem.data.rotate,
      camera: missionItem.data.camera,
      ...values,
    });
  };

  const getDistanceByOptions = (altitude, aov, overlap) => {
    const distance = Math.tan(degreesToRadians(aov / 2)) * altitude * 2;
    const value = distance * (1 - overlap / 100);

    return Number(value.toFixed(1));
  };

  const toggleHasCamera = () => {
    if (hasCamera) {
      const positions = getPositionsByChangedOption({ camera: null });
      editSurvey({ positions, camera: null });
    } else {
      changeCamera(CAMERAS[0]);
    }
  };

  return (
    <div className={cx('container')}>
      <MissionItemRow label="Relative Altitude" unit="m">
        <input
          ref={domRefs.relAltitude}
          name="relAltitude"
          type="number"
          onBlur={changeRelAltitude}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Gap" unit="m">
        <input
          ref={domRefs.gap}
          name="gap"
          type="number"
          readOnly={hasCamera}
          onBlur={changeGap}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Before turnaround" unit="m">
        <input
          ref={domRefs.turnaround.before}
          name="turnaround.before"
          type="number"
          onBlur={changeTurnaround}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="After turnaround" unit="m">
        <input
          ref={domRefs.turnaround.after}
          name="turnaround.after"
          type="number"
          onBlur={changeTurnaround}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Rotate" unit="°">
        <input
          ref={domRefs.rotate}
          name="rotate"
          type="number"
          onBlur={changeRotate}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              e.target.blur();
            }
          }}
        />
      </MissionItemRow>
      <MissionItemRow label="Camera">
        <Checkbox checked={hasCamera} onClick={toggleHasCamera} />
        <select ref={domRefs.camera} disabled={!hasCamera} onChange={handleSelect}>
          {!hasCamera && <option value="">None</option>}
          {CAMERAS.map((camera, index) => (
            <option key={index} value={camera.name}>
              {camera.name}
            </option>
          ))}
        </select>
      </MissionItemRow>
      {hasCamera && (
        <>
          <MissionItemRow label="Sensor Size" unit="㎜">
            <input
              type="text"
              value={`${missionItem.data.camera.options.sensorSize.width} x ${missionItem.data.camera.options.sensorSize.height}`}
              readOnly
            />
          </MissionItemRow>
          <MissionItemRow label="Focal Length" unit="㎜">
            <input type="number" value={missionItem.data.camera.options.focalLength} readOnly />
          </MissionItemRow>
          <MissionItemRow label="Forward Overlap" unit="%">
            <input
              ref={domRefs.overlap.forward}
              name="overlap.forward"
              type="number"
              onBlur={changeOverlap}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.target.blur();
                }
              }}
            />
          </MissionItemRow>
          <MissionItemRow label="Side Overlap" unit="%">
            <input
              ref={domRefs.overlap.side}
              name="overlap.side"
              type="number"
              onBlur={changeOverlap}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.target.blur();
                }
              }}
            />
          </MissionItemRow>
          <MissionItemRow label="Interval" unit="m">
            <input type="number" value={missionItem.data.camera.interval} readOnly />
          </MissionItemRow>
          <MissionItemRow label="Shoot Count">
            <input type="number" value={shootCount} readOnly />
          </MissionItemRow>
        </>
      )}
    </div>
  );
};

export default MissionItem;
