import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import moment from 'moment';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';

import styles from './common.module.scss';
import ChecklistModal from '../../modals/Checklist';

import actions from '@/actions';
import ConfirmSlider from '@/components/ui/ConfirmSlider';
import { MessageContext } from '@/helpers/MessageProvider';
import OlMap from '@/helpers/OlMap';
import EventEmitter from '@/libs/EventEmitter';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);

  useMountEffect(() => {
    const isNeedChecklist = (robotId) => {
      const storeKey = `@preventChecklist/${robotId}`;
      return localStorage.getItem(storeKey) !== moment().format('YYYYMMDD');
    };

    robots.forEach((robot) => {
      if (isNeedChecklist(robot.id)) {
        dispatch(actions.command.toggle());
        modal.show(ChecklistModal, { robotId: robot.id });
      }
    });
  });

  const doCommand = () => {
    robots.forEach((robot) => {
      publishCommand(robot, 'action/arm', [[], []]);
      EventEmitter.publish(`${robot.id}/event/resetGoto`);

      OlMap.removeShoot(robot.id);
      OlMap.removeFootprint(robot.id);
    });

    dispatch(actions.command.toggle());
  };

  return (
    <div className={cx('container')}>
      <div className={cx('message')}>Force Arming</div>
      <ConfirmSlider onConfirm={doCommand} />
    </div>
  );
};

export default Action;
