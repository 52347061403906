import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useState, useEffect, useMemo } from 'react';

import styles from './Tutorial.module.scss';
import { MENUS } from '../../define/tutorial';
import ModalWrapper from '../ui/Modal';

import Checkbox from '@/components/ui/Checkbox';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Modal = () => {
  const [indexes, setIndexes] = useState({ sup: 0, sub: 0 });
  const [sceneIndex, setSceneIndex] = useState();
  const [isDontShow, setIsDontShow] = useState(false);

  const scenes = useMemo(() => {
    const sup = MENUS[indexes.sup];
    if (Object.hasOwn(sup, 'menus')) {
      return sup.menus[indexes.sub].scenes;
    }
    return sup.scenes;
  }, [indexes]);

  const scene = useMemo(() => {
    if (sceneIndex === undefined) return;

    return scenes[sceneIndex];
  }, [sceneIndex]);

  useEffect(() => {
    // 첫 번째 장면
    setSceneIndex(0);
  }, [indexes]);

  useUpdateEffect(() => {
    if (sceneIndex === undefined) return;

    // 장면 전환
    const timeout = setTimeout(() => {
      setSceneIndex((prev) => (prev + 1) % scenes.length);
    }, scene.duration);

    return () => {
      clearTimeout(timeout);
    };
  }, [sceneIndex]);

  // 항목 선택
  const selectSup = (e) => {
    const supIndex = Number(e.currentTarget.dataset.sup);

    setSceneIndex();
    setIndexes({
      sup: supIndex,
      sub: 0,
    });
  };

  // 하위 항목 선택
  const selectSub = (e) => {
    setSceneIndex();
    setIndexes({
      sup: Number(e.currentTarget.dataset.sup),
      sub: Number(e.currentTarget.dataset.sub),
    });
  };

  const toggleIsDontShow = () => {
    setIsDontShow(!isDontShow);
  };

  const close = () => {
    // 다음 노출 방지
    if (isDontShow) {
      localStorage.setItem('@preventTutorial', Date.now());
    }

    modal.hide();
  };

  return (
    <ModalWrapper>
      <div className={cx('wrapper')}>
        <div className={cx('menus')}>
          {MENUS.map((sup, supIndex) => {
            const isSelectedSup = supIndex === indexes.sup;

            return (
              <div key={supIndex} className={cx('menuGroup')}>
                <div data-sup={supIndex} onClick={selectSup} className={cx(['menu', { target: isSelectedSup }])}>
                  {sup.title}
                </div>
                {sup.menus && (
                  <div className={cx('submenus')}>
                    {sup.menus.map((sub, subIndex) => {
                      const isSelectedSub = subIndex === indexes.sub;

                      return (
                        <div
                          key={subIndex}
                          data-sup={supIndex}
                          data-sub={subIndex}
                          onClick={selectSub}
                          className={cx(['submenu', { target: isSelectedSup && isSelectedSub }])}>
                          {sub.title}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className={cx('content')}>
          {scenes.map((scene, index) => (
            <img
              key={index}
              src={scene.image}
              alt={MENUS[indexes.sup].title}
              className={cx(['image', { target: index <= sceneIndex }])}
            />
          ))}
          {scene?.desc && <div className={cx('desc')}>{scene.desc}</div>}
        </div>
      </div>
      <div className={cx('bottom')}>
        <button onClick={close} type="button" className={cx('button')}>
          Close
        </button>
        <div className={cx('option')} onClick={toggleIsDontShow}>
          <Checkbox checked={isDontShow} size={16} />
          <div className={cx('dont')}>Don't show again</div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
