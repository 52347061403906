import classNames from 'classnames/bind';
import moment from 'moment';
import React, { useState } from 'react';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill } from 'react-icons/ri';

import styles from './Checklist.module.scss';

import ModalWrapper from '@/components/ui/Modal';
import { ModalService as modal } from '@/libs/Modal';
import { ToastService as toast } from '@/libs/Toast';

const cx = classNames.bind(styles);

const QUESTIONS = [
  '사고나 분실에 대비해 장치에는 쇼유자 이름, 연락처를 기재 하도록 합니다.',
  '항상 육안거리 내에서 조종합니다.',
  '음주 상태에서 조종하지 않습니다.',
  '조종하기전 해당제품의 메뉴얼을 숙지합니다.',
  '전자인증을 받은 제품인지 확인합니다.',
];

const Modal = ({ robotId }) => {
  const [checks, setChecks] = useState(new Array(QUESTIONS.length).fill(false));

  const close = () => {
    modal.hide();
  };

  const check = (index) => {
    const nextChecks = [...checks];
    nextChecks[index] = !checks[index];
    setChecks(nextChecks);
  };

  const confirm = () => {
    const isAllChecked = checks.every((check) => check);
    if (!isAllChecked) {
      toast.error('Please check all items.');
      return;
    }

    const storeKey = `@preventChecklist/${robotId}`;
    localStorage.setItem(storeKey, moment().format('YYYYMMDD'));
    close();
  };

  return (
    <ModalWrapper>
      <div className={cx('wrapper')}>
        <div className={cx('table')}>
          <div className={cx('header')}>
            <div className={cx(['column', 'no'])}>No</div>
            <div className={cx(['column', 'question'])}>Question</div>
            <div className={cx(['column', 'check'])}>Check</div>
          </div>
          <div className={cx('body')}>
            {QUESTIONS.map((question, index) => (
              <div key={index} onClick={() => check(index)} className={cx('row')}>
                <div className={cx(['column', 'no'])}>{index + 1}</div>
                <div className={cx(['column', 'question'])}>{question}</div>
                <div className={cx(['column', 'check'])}>
                  {checks[index] ? (
                    <RiCheckboxCircleFill size={20} color="#41a3ff" />
                  ) : (
                    <RiCheckboxBlankCircleLine size={20} color="white" />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={cx('bottom')}>
        <button onClick={close} type="button" className={cx('button')}>
          Cancel
        </button>
        <button onClick={confirm} type="button" className={cx(['button', 'accent'])}>
          Confirm
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
