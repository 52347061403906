export const MENUS = [
  {
    title: 'Map Toolbar',
    scenes: [
      {
        image: require('../assets/images/tutorial/01-01.jpg'),
        duration: 2000,
        desc: '',
      },
      {
        image: require('../assets/images/tutorial/01-02.jpg'),
        duration: 2000,
        desc: '',
      },
      {
        image: require('../assets/images/tutorial/01-03.jpg'),
        duration: 2000,
        desc: '',
      },
      {
        image: require('../assets/images/tutorial/01-04.jpg'),
        duration: 2000,
        desc: '',
      },
      {
        image: require('../assets/images/tutorial/01-05.jpg'),
        duration: 3000,
        desc: '',
      },
    ],
  },
  {
    title: 'Weather',
    scenes: [
      {
        image: require('../assets/images/tutorial/02-01.jpg'),
        duration: 1000,
        desc: '',
      },
      {
        image: require('../assets/images/tutorial/02-02.jpg'),
        duration: 3000,
        desc: '',
      },
    ],
  },
  {
    title: 'Agent Booting',
    scenes: [
      {
        duration: 1000,
        image: require('../assets/images/tutorial/03-01.jpg'),
        desc: '',
      },
    ],
  },
  {
    title: 'Commands',
    scenes: [
      {
        image: require('../assets/images/tutorial/04-01.jpg'),
        duration: 1000,
        desc: '',
      },
      {
        image: require('../assets/images/tutorial/04-02.jpg'),
        duration: 2000,
        desc: '',
      },
      {
        image: require('../assets/images/tutorial/04-03.jpg'),
        duration: 2000,
        desc: '',
      },
      {
        image: require('../assets/images/tutorial/04-04.jpg'),
        duration: 3000,
        desc: '',
      },
    ],
  },
  {
    title: 'Mission Hub',
    scenes: [
      {
        image: require('../assets/images/tutorial/05-01.jpg'),
        duration: 2000,
        desc: '',
      },
      {
        image: require('../assets/images/tutorial/05-02.jpg'),
        duration: 3000,
        desc: '',
      },
    ],
  },
  {
    title: 'Edit a Mission',
    menus: [
      {
        title: 'Add Point',
        scenes: [
          {
            image: require('../assets/images/tutorial/06-01.jpg'),
            duration: 500,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/06-02.jpg'),
            duration: 500,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/06-03.jpg'),
            duration: 500,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/06-04.jpg'),
            duration: 500,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/06-05.jpg'),
            duration: 3000,
            desc: '',
          },
        ],
      },
      {
        title: 'Draw Line',
        scenes: [
          {
            image: require('../assets/images/tutorial/07-01.jpg'),
            duration: 300,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/07-02.jpg'),
            duration: 300,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/07-03.jpg'),
            duration: 300,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/07-04.jpg'),
            duration: 300,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/07-05.jpg'),
            duration: 300,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/07-06.jpg'),
            duration: 3000,
            desc: '',
          },
        ],
      },
      {
        title: 'Draw Area',
        scenes: [
          {
            image: require('../assets/images/tutorial/08-01.jpg'),
            duration: 500,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/08-02.jpg'),
            duration: 500,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/08-03.jpg'),
            duration: 500,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/08-04.jpg'),
            duration: 500,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/08-05.jpg'),
            duration: 3000,
            desc: '',
          },
        ],
      },
      {
        title: 'Draw Polygon',
        scenes: [
          {
            image: require('../assets/images/tutorial/09-01.jpg'),
            duration: 500,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/09-02.jpg'),
            duration: 500,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/09-03.jpg'),
            duration: 500,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/09-04.jpg'),
            duration: 500,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/09-05.jpg'),
            duration: 500,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/09-06.jpg'),
            duration: 3000,
            desc: '',
          },
        ],
      },
      {
        title: 'Change Altitude',
        scenes: [
          {
            image: require('../assets/images/tutorial/10-01.jpg'),
            duration: 1000,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/10-02.jpg'),
            duration: 1000,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/10-03.jpg'),
            duration: 1000,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/10-04.jpg'),
            duration: 1000,
            desc: '',
          },
          {
            image: require('../assets/images/tutorial/10-05.jpg'),
            duration: 3000,
            desc: '',
          },
        ],
      },
    ],
  },
  {
    title: 'Save a Mission',
    scenes: [
      {
        image: require('../assets/images/tutorial/11-01.jpg'),
        duration: 1000,
        desc: '',
      },
    ],
  },
  {
    title: 'Load a Mission',
    scenes: [
      {
        image: require('../assets/images/tutorial/12-01.jpg'),
        duration: 2000,
        desc: '',
      },
      {
        image: require('../assets/images/tutorial/12-02.jpg'),
        duration: 2000,
        desc: '',
      },
      {
        image: require('../assets/images/tutorial/12-03.jpg'),
        duration: 2000,
        desc: '',
      },
      {
        image: require('../assets/images/tutorial/12-04.jpg'),
        duration: 3000,
        desc: '',
      },
    ],
  },
];
