import classNames from 'classnames/bind';
import React, { useRef } from 'react';
import {
  RiFile3Line,
  RiFolderOpenLine,
  RiSave3Line,
  RiFileDownloadLine,
  RiFileUploadLine,
  RiEqualizerLine,
} from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import styles from './Sidebar.module.scss';

import actions from '@/actions';
import EditorConfigModal from '@/components/modals/EditorConfig';
import MissionListModal from '@/components/modals/MissionList';
import { postMission, patchMission } from '@/helpers/Requester';
import { ModalService as modal } from '@/libs/Modal';
import { ToastService as toast } from '@/libs/Toast';

const cx = classNames.bind(styles);

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const name = useSelector((state) => state.editor.name);
  const missionItems = useSelector((state) => state.editor.missionItems);
  const params = useParams();
  const fileRef = useRef();

  const replaceToNew = () => {
    window.location.replace('/mission-hub/new');
  };

  const doLoad = () => {
    modal.show(MissionListModal);
  };

  const validAll = () => {
    if (missionItems.length === 0) {
      toast.error('No mission defined.');
      return false;
    }
    if (name.trim() === '') {
      toast.error('Please enter the mission name.');
      return false;
    }
    return true;
  };

  const doSave = () => {
    if (!validAll()) return;

    let request;
    if (params.id === 'new') {
      request = postMission(name, missionItems);
    } else {
      request = patchMission(params.id, name, missionItems);
    }

    request.then(({ success, data }) => {
      if (success) {
        dispatch(actions.editor.save());
        navigate(`/mission-hub/${data?.id ?? params.id}`, { replace: true });
        toast.success('The mission have been saved.');
      }
    });
  };

  const doSaveAs = () => {
    if (!validAll()) return;

    // 각 미션 아이템 신규 ID 정의
    const newMissionItems = missionItems.map((missionItem) => ({
      ...missionItem,
      id: uuidv4(),
    }));

    postMission(name, newMissionItems).then(({ success, data }) => {
      if (success) {
        dispatch(actions.editor.save());
        navigate(`/mission-hub/${data.id}`, { replace: true });
        toast.success('The mission have been saved with new name.');
      }
      // 미션명 존재하는 경우
      else if (data.code === 2001) {
        toast.error('The mission name already exists.');
      }
    });
  };

  const showConfigPanel = () => {
    modal.show(EditorConfigModal);
  };

  const doDownload = () => {
    if (!validAll()) return;

    const link = document.createElement('a');
    link.download = `${new Date().toISOString()}.json`;
    link.href = URL.createObjectURL(new Blob([JSON.stringify({ name, json: missionItems })], { type: 'text/json' }));
    link.click();
    link.remove();
  };

  const doUpload = () => {
    fileRef.current.click();
  };

  const handleInput = (e) => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (file) => {
      const data = JSON.parse(file.target.result);
      dispatch(actions.editor.load(data));
      navigate('/mission-hub/new', { replace: true });
    };
    e.target.value = '';
  };

  return (
    <div className={cx('container')}>
      <input ref={fileRef} hidden type="file" accept=".json" onInput={handleInput} />
      <div className={cx('sidebar')}>
        <div className={cx('logo')}>
          <img src={require('@/assets/images/logo.png')} width="36" alt="M1UCS" />
        </div>
        <div className={cx('wrapper')}>
          <div className={cx(['menus', 'top'])}>
            <div onClick={replaceToNew} className={cx('menu')}>
              <RiFile3Line size={28} color="white" />
              <div className={cx('name')}>New</div>
            </div>
            <div onClick={doLoad} className={cx('menu')}>
              <RiFolderOpenLine size={28} color="white" />
              <div className={cx('name')}>Load</div>
            </div>
            <div onClick={doSave} className={cx('menu')}>
              <RiSave3Line size={28} color="white" />
              <div className={cx('name')}>Save</div>
            </div>
            <div onClick={doSaveAs} className={cx('menu')}>
              <div className={cx('icon')}>
                <RiSave3Line size={28} color="white" />
                <div className={cx('as')}>As</div>
              </div>
              <div className={cx('name')}>Save As</div>
            </div>
            <div onClick={doDownload} className={cx('menu')}>
              <RiFileDownloadLine size={28} color="white" />
              <div className={cx('name')}>Download</div>
            </div>
            <div onClick={doUpload} className={cx('menu')}>
              <RiFileUploadLine size={28} color="white" />
              <div className={cx('name')}>Upload</div>
            </div>
          </div>
          <div className={cx(['menus', 'bottom'])}>
            <div onClick={showConfigPanel} className={cx('menu')}>
              <RiEqualizerLine size={28} color="white" />
              <div className={cx('name')}>Config</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
