import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useSelector } from 'react-redux';

import useWaitingRobotTimer from '@/hooks/useWaitingRobotTimer';
import EventEmitter from '@/libs/EventEmitter';
import { isColdStartLocation } from '@/utils/MapUtils';

const Organizer = ({ data: robot }) => {
  const telemetry = useSelector((state) => state.telemetry[robot.id]);

  useWaitingRobotTimer(robot.id, [telemetry]);

  useUpdateEffect(() => {
    // GPS 데이터 유효하지 않은 경우
    if (isColdStartLocation(telemetry.latitude, telemetry.longitude)) return;

    EventEmitter.publish(`${robot.id}/telemetry`, telemetry);
  }, [telemetry]);

  return null;
};

export default Organizer;
